<template>
  <div class="partners container">

    <!-- ------------------------------- Modal: Add/Modify partner ------------------------------------------ -->

	<CModal title="Partner Details" color="info" :show.sync="partnerModal" size="lg">	
		<div slot="header">
			<h5>{{this.partnerType}}: {{partner.name}}</h5>
		</div>	  
		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdatePartner')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="partnerModal=false">Close</CButton>
		</div>

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-9 form-control" v-model="partner.name">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">GSTN</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.GSTN">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">PAN</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.PAN">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Aadhar</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.aadhar">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Phone</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.phone">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Email</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.email">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Address1</label>
				<input type="text" class="col-sm-8 form-control" v-model="partner.address1">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Address2</label>
				<input type="text" class="col-sm-8 form-control" v-model="partner.address2">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Address3</label>
				<input type="text" class="col-sm-8 form-control" v-model="partner.address3">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">State</label>
				<!-- <input type="text" class="col-sm-3 form-control" v-model="partner.state"> -->
				<select class="col-sm-3 form-control form-select" v-model="partner.state">
					<option v-for="state in stateList" v-bind:value="state">
						{{ state }}
					</option>
				</select>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Zipcode</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.zipcode">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Transporter</label>
				<!-- <input type="text" class="col-sm-3 form-control" v-model="partner.transporter"> -->
				<select class="col-sm-3 form-control form-select" v-model="partner.transporter">
					<option v-for="option in transporterList" v-bind:key="option.nbr" v-bind:value="option.name">
						{{ option.name }}
					</option>
				</select>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Transport Dest</label>
				<input type="text" class="col-sm-3 form-control" v-model="partner.transportDest">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Bank</label>
				<textarea class="col-sm-8 form-control" v-model="partner.bank" placeholder="Bank Information"></textarea>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Note</label>
				<textarea class="col-sm-8 form-control" v-model="partner.note" placeholder="Special Instructions"></textarea>
			</div>

			<p class="text-danger" align="left">{{partnerAlert}}</p>
		</form>

	</CModal>


    <!-- ---------------------------------------  Partner List -------------------------------------------------- -->

	<p class="font-weight-bold" align="center">{{this.partnerType}}s</p>
	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>
	<vue-good-table ref="partnersTable" 
							:columns="colPartners" 
							:rows="rowPartners" 
							:pagination-options="pagination_options" 
							@on-row-click="rowChanged" 
							:selectOptions="{
									enabled: true,
									selectOnCheckboxOnly: false, 
							}">
			<div slot="table-actions">
				<CButton color="dark" class="mr-3" v-on:click.prevent="openNewPartnerDetails()">New</CButton>
				<CButton color="dark" class="mr-2" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
			<div slot="selected-row-actions">
				<CButton color="dark" v-on:click.prevent="openExistingPartnerDetails()">Details</CButton>
				<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deletePartner')">Delete</CButton>
			</div>
	</vue-good-table>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'partners',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {
		return {
			orgId: null,

			partnerType: 'Customer', 

			alert: '',
			partnerAlert: '',
            loading: false,
            loadingMsg: null,

			partnerModal: false,

            orgTimeZone: null,

			partners: [],
			partner: {},
			transporterList: [],
			stateList: ["Andhra Pradesh","Andaman and Nicobar Islands","Arunachal Pradesh","Assam","Bihar",
						"Chandigarh","Chhattisgarh","Dadar and Nagar Haveli","Daman and Diu","Delhi","Lakshadweep",
						"Puducherry","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand",
						"Karnataka","Kerala","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram",
						"Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura",
						"Uttar Pradesh","Uttarakhand","West Bengal"],

			colPartners: [
					{
							label: 'Name',
							field: 'name',
							html: false,
							filterOptions: {enabled: true,},
					},
					{
							label: 'State',
							field: 'state',
							html: false,
							filterOptions: {enabled: true,},
					},
					{
							label: 'Phone',
							field: 'phone',
							html: false,
							filterOptions: {enabled: false,},
					},
					{
							label: 'Transporter',
							field: 'transporter',
							html: false,
							filterOptions: {enabled: true,},
					},
			],



			rowPartners: [],
			rowPartnersHtml: '',

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},

			curRowSelected: null,

			reportLongName: "",
			reportData: "",
		}
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		// todate = current date
		this.todate = new Date()

		this.partnerType = this.$route.params['partnerType']
		this.serverCall('getPartners')
	},

	// watch: {
	// 	$route(to, from) {
	// 		console.log("Partners - watch")
	// 		this.partnerType = this.$route.params['partnerType']
	// 		this.serverCall('getPartners')
	// 	}
	// },

	methods: {

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getPartners":
								self.getPartners(idToken)
								break;

							case "addUpdatePartner":
								self.addUpdatePartner(idToken)
								break;

							case "deletePartner":
								self.deletePartner(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Partners - Please login first."
				}
			})
		},

        // Fetch partners for the given org
        getPartners(authToken) {
            var self = this
			self.alert = null

			const options = { year: '2-digit', month: 'short', day: 'numeric' }
			const currentDt = new Date()
			const currentDateStr = currentDt.toLocaleDateString("en-US", options)

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching " + self.partnerType + "..."

            var formData = new FormData()
			formData.append("partnerType", self.partnerType)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetPartners/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.rowPartners = response.data.partnerList
						self.transporterList = response.data.transporterList[0]

						self.reportLongName = self.partnerType + "s - " + currentDateStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})

		},
		
		rowChanged(params){	
			// Current selected Row
			if (this.curRowSelected != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected.originalIndex!=params.row.originalIndex)
					this.curRowSelected.vgtSelected = false
			}
			// New selected row
			this.curRowSelected = params.row
			this.partner= this.curRowSelected
		},

		// Open details for existing Partner
		openExistingPartnerDetails(){
            var self = this
			self.alert = null
			self.partnerAlert = null

            // Check if partner is selected
			if (self.curRowSelected==null){
				self.alert = "Partner not selected."
				return
			}

			self.partner= self.curRowSelected

			self.partnerModal = true
		},

		// Open details for new partner
		openNewPartnerDetails(){
			this.alert = null
			this.partnerAlert = null

			// Initialize partner object
			this.partner = {
				nbr: '',
				type: '',
				name: '',
				phone: '',
				email: '',
				GSTN: '',
				state: '',
				address1: '',
				address2: '',
				address3: '',
				transporter: '',
				transportDest: '',
				bank: '',
				note: '',
				vgtSelected: false,
				optOutOfNetwork: 'No'
			}

			this.partnerModal = true
		},

		// Save partner changes - for new as well as existing partner
        addUpdatePartner(authToken) {
            var self = this
            this.partnerAlert = ""

            if (!self.partner.name) {
                self.partnerAlert= self.partnerType + ' name not specified.'
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Adding/Updting Partner..."

			var formData = new FormData()
			self.partner.type = self.partnerType
			formData.append('partner', JSON.stringify(self.partner))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdatePartner/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

					if (response.data.err != '') {
						self.partnerAlert = response.data.err
					}
					else {
						// Refresh partner list on the screen
						self.getPartners(authToken)						
						self.partnerModal = false
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.partnerAlert = String(error)
				})

		},

		// Delete Partner
		deletePartner(authToken){
            var self = this
			self.alert = null
			self.partnerAlert = null

            // Check if partner is selected
			if (self.curRowSelected==null){
				self.alert = "Partner not selected."
				return
			}
			else{
				self.partner = self.curRowSelected
			}

			this.$confirm(self.partnerType + ": " + self.partner.name + " - will be deleted. Are you sure?").then(() => {
					// Set loading message
					self.loading = true
					self.loadingMsg = "Deleting Partner..."

					var formData = new FormData()
					formData.append('partnerDocId', self.partner.nbr)

					axios({
							method: 'POST',
							baseURL: process.env.VUE_APP_ROOT_API,
							url: '/DeletePartner/',
							data: formData,
							headers: {
								'Authorization': 'Bearer ' + authToken,
								'OrgId': self.orgId,
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(function (response) {
							// Reset loading message
							self.loading = false
							self.loadingMsg = null

							if (response.data.err != ''){
								self.alert = response.data.err
							}
							else {
								// Refresh partner list on the screen
								self.getPartners(authToken)
							}					
						})
						.catch(function (error) {
							// Reset loading message
							self.loading = false
							self.loadingMsg = null
							self.deviceAlert = String(error)
						})
			})

		},

		downloadReport() {
			if (this.reportData==[]){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},

	},

}
</script>

